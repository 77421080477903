(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name AnalisisController
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('AnalisisController', AnalisisController);

    AnalisisController.$inject = ["AnaliticasService","$timeout","$location",'$anchorScroll'];

    function AnalisisController(AnaliticasService,$timeout,$location,$anchorScroll)
    {

    	var vm = this;
    	vm.analiticas = [];
    	vm.tiposAnalisis = [];
        vm.buscador = {};
	    vm.loadingMuestras = false;
        vm.tipoSeleccionado = [];
    	vm.init = function(){
	        vm.loadingMuestras = true;
    		AnaliticasService.getAnaliticas(function(data){
	            vm.analiticas = data.analiticas;
	            vm.tiposAnalisis = data.tiposAnalisis;
	            vm.loadingMuestras = false;
	        });
    	}

         vm.contieneTipoAnalitica = function(analiticas_ids,tipo_id){
            var res = false;
            if(analiticas_ids!=null){
                var analiticasSplit = analiticas_ids.split(",");
                if(analiticasSplit.length>0){
                    for(var cont=0;cont<analiticasSplit.length && !res;cont++){
                        if(analiticasSplit[cont]==tipo_id){
                            res = true;
                        }
                    }
                }
            }
            return res;
            //console.log(analiticas_ids);

        }

         vm.seleccionarTipoAnalitica = function(tipo){
            vm.tipoSeleccionado = [tipo.id];
            vm.buscador.nombre = "";
        }


        vm.contieneTipoSeleccionado = function(id){
            var res = false;
            for(var cont=0;cont<vm.tipoSeleccionado.length && !res;cont++){
                if(vm.tipoSeleccionado[cont]==id){
                    res = true;
                }
            }
            return res;
        }


        vm.contieneBuscador = function(subtipo){
            var res = true;
            if(vm.buscador.nombre!=""){
                res = false;
                angular.forEach(vm.analiticas,function(data){
                    //console.log(data.analiticapadre_id);
                    var re = new RegExp(vm.buscador.nombre, 'i');
                    if(/*data.nombre.indexOf(vm.buscador.nombre) > -1*/data.nombre.match(re) && data.tipoanalitica_id==subtipo.id){
                        res = true;
                    }
               })
            }
            return res;
        }

               
    }


})();