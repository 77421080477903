(function(){
    angular
        .module('app')
        .directive('scrollHeader', setclassonscrollHeader);

        setclassonscrollHeader.$inject = ['$timeout','$window'];
        function setclassonscrollHeader($timeout,$window){
        	var $win = angular.element($window); // wrap window object as jQuery object
            return {
            	link: function (scope, element, $attrs) {
		            return $timeout(function () {
	

		            	var topClass = $attrs.scrollHeader; // get CSS class from directive's attribute value
						let firstTop = element.offset().top;
						const topDisplay = 150;
						var scrollPos = 0;
			            $win.on('scroll', function (e) {
                            if ($win.scrollTop() > topDisplay) {
                                element.addClass(topClass);
                                if(!element[0].classList.contains('animating')) {
                                    element.addClass('animating');
                                }
                                
                            } else {
                                element.removeClass(topClass);
                                element.removeClass('animating');
                            }
                            if($win.width() < 1000 ) {
                                if($win.scrollTop() == firstTop){
                                    element.addClass('animating');
                                    setTimeout(() => {
                                        element.removeClass('animating');
                                    }, 500);
                                }
                                if((document.body.getBoundingClientRect()).top > scrollPos) {
                                    element.addClass('animating');
                                    element.removeClass('hide-mov');
                                } else  {
                                    element.addClass('hide-mov');
                                }
                                if($win.scrollTop() == firstTop) {
                                    element.removeClass(topClass)
                                }
                                scrollPos = (document.body.getBoundingClientRect()).top;
                            }
							


			            });

			        });
		        }
          }
      }
})();