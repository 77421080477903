(function(){
    'use strict';
   
    angular
    .module('app')
    .controller('MapController', MapController)
 
    MapController.$inject = ["LocalizadorService","$http","$timeout","$log", "$document","$sce","$scope","uiGmapGoogleMapApi"];
   
    function MapController(LocalizadorService,$http,$timeout, $log, $document,$sce,$scope,GoogleMapApi)
    {                  
        console.log("E");      
        var vm = this; 
        vm.icon = "/img/i_marker.png";
        //vm.iconiai = "/imgn/i_markeriai.jpg";
        vm.cluster = "/img/i_cluster.png";
        vm.pais = "";
        vm.esiai = "todos";
        vm.zip="";
        vm.provincia = "";
        vm.countrycode = 'es';
        vm.selectedCentroId = null;
        vm.especialidad = {
          s1:false,
          s2:false,
          s3:false,
          s4:false
        };

        vm.localizado = false;

        vm.contadorVisibles = 0;

        vm.mapEvents = {
          bounds_changed:function (map) {
            return GoogleMapApi.then((function(_this) {
                return function(maps) {

                  var bounds = new google.maps.LatLngBounds();
                  vm.contadorVisibles = 0;
                  for(var cont=0;cont<vm.centros.length;cont++){
                      vm.centros[cont].visible = false;
                      var latlong = new google.maps.LatLng(Number(vm.centros[cont].lat), Number(vm.centros[cont].long));
                      if(/*vm.centros[cont].country == vm.pais &&*/ map.getBounds().contains(latlong)){
                        vm.centros[cont].visible = true;
                        vm.contadorVisibles++;
                      }
                  }
                }
            })(this));
          }

        };
        var istouch = "ontouchstart" in document.documentElement;
        vm.map = { 
            center: { latitude: '41.3850639', longitude: '2.1734034' }, 
            zoom: 8,
            clusterOptions: {
              minimumClusterSize : 6,
              zoomOnClick: true,
              styles: [{
                  url: vm.cluster,
                  width:32,
                  height:32,
                  textColor: 'black',
                  textSize: 13
              }],
              averageCenter: true,
              clusterClass: 'cluster-icon'
            },
            options:{
              gestureHandling: (istouch ?  'cooperative':'greedy'),
              styles: [
                {
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#f5f5f5"
                    }
                  ]
                },
                {
                  "elementType": "labels.icon",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#616161"
                    }
                  ]
                },
                {
                  "elementType": "labels.text.stroke",
                  "stylers": [
                    {
                      "color": "#f5f5f5"
                    }
                  ]
                },
                {
                  "featureType": "administrative",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "administrative.land_parcel",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "administrative.land_parcel",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#bdbdbd"
                    }
                  ]
                },
                {
                  "featureType": "administrative.neighborhood",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#eeeeee"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "elementType": "labels.text",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#757575"
                    }
                  ]
                },
                {
                  "featureType": "poi.park",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#e5e5e5"
                    }
                  ]
                },
                {
                  "featureType": "poi.park",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#9e9e9e"
                    }
                  ]
                },
                {
                  "featureType": "road",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#ffffff"
                    }
                  ]
                },
                {
                  "featureType": "road.arterial",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#757575"
                    }
                  ]
                },
                {
                  "featureType": "road.highway",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#dadada"
                    }
                  ]
                },
                {
                  "featureType": "road.highway",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#616161"
                    }
                  ]
                },
                {
                  "featureType": "road.local",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#9e9e9e"
                    }
                  ]
                },
                {
                  "featureType": "transit",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "transit.line",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#e5e5e5"
                    }
                  ]
                },
                {
                  "featureType": "transit.station",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#eeeeee"
                    }
                  ]
                },
                {
                  "featureType": "water",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#c9c9c9"
                    }
                  ]
                },
                {
                  "featureType": "water",
                  "elementType": "labels.text",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "water",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#9e9e9e"
                    }
                  ]
                }
              ]
            },
            markers: [{id:1,coords:{latitude:45,longitude:-73}}],
            window: {
              marker: {},
              show: false,
              closeClick: function() {
                this.show = false;
              },
              model:{
                centro:{
                  id: 0,
                  address: "test"
                }
              },
              options: {
              } 
            }
        };

        vm.markers = [],

        vm.coords = {};
        vm.radio = 10000;

        vm.centros = [];
        vm.bnds = "";

        vm.address = "";

        vm.changeCoords = function(lat_gm,long_gm){
          vm.coords.lat = lat_gm;
          vm.coords.long = long_gm;
        }

        vm.cambiarIai = function(iai){
          vm.esiai = iai;
        }

        vm.getLatitudeLongitude = function(callback) {
          return GoogleMapApi.then((function(_this) {
                return function(maps) {
                  var address = vm.address || vm.country;
                  var geocoder = new google.maps.Geocoder();
                  if (geocoder) {
                      geocoder.geocode({
                          'address': address,
                          'componentRestrictions':{'country':vm.countrycode}
                      }, function (results, status) {
                          if (status == google.maps.GeocoderStatus.OK) {
                              callback(results[0]);
                          }
                      });
                  }
                }
            })(this));
        }

        vm.changePais = function(country,code,lat_gm,long_gm,pais_id){
          vm.map.window.show = false;
          vm.pais=country;
          vm.pais_id=pais_id;
          console.log(vm.pais_id);
          vm.address='';
          vm.countrycode=code;
          vm.radio = 10000;
          vm.setMarkersDireccion(function(){
            vm.changeCoords(lat_gm,long_gm);
            vm.getCenterCentros(false);
          })
          
        }

        vm.centrar = function(lat_gm,long_gm){
          vm.map.center = { latitude: lat_gm, longitude: long_gm };
          vm.map.zoom = 15;
        }
        vm.storeInfo = function(centro){
          /*digitalData.event = [{eventInfo:{ "eventName":"store_locator_details", "attributes": "Click details center "+centro.name },"category":{primaryCategory:"store_locator"}}];
          digitalData.component.storeLocator.name = centro.name;
          digitalData.component.storeLocator.geo = { latitude:centro.lat, longitude:centro.long };
          digitalData.component.storeLocator.email = centro.email;
          digitalData.component.storeLocator.telephone = centro.phone;
          digitalData.component.storeLocator.address = { addressCountry: centro.country, addressLocality:centro.ciudad, addressRegion:'', postalCode:centro.zip, streetAddres:centro.address };
          digitalData.cfg = true;
          window.wa_gc(digitalData, "view");*/
        }

        vm.details = function(centro){
          vm.storeInfo(centro);
        }

        vm.buscar = function(){
            
            if(vm.address==""){
                vm.radio=10000;
            }else{
                vm.radio = 3.5;
            }
            vm.getLatitudeLongitude(function(result){
                vm.coords.lat = result.geometry.location.lat();
                vm.coords.long = result.geometry.location.lng();
                vm.getCenterCentros(false);
            });
        }

        vm.centrarMapa = function(lat,lng,zoom){
          //var pos = new google.maps.LatLng(lat,lng)
          vm.map.window.show = false;
          vm.map.zoom = zoom;
          vm.map.center = {
            latitude: lat,
            longitude: lng
          };
        }

        vm.goScroll = function(centro){
          $(".scroll").animate({
            scrollTop: $(".scroll").scrollTop()+$(".store_"+centro.id).position().top-40
          }, 800);
        }

        vm.markersEvents = {
          click: function (gMarker, eventName, model) {
            if(model.centro!=""){
              $timeout(function(){
                vm.map.window.model = model;
                vm.map.window.show = true;
                vm.storeInfo(model.centro);
                vm.goScroll(model.centro);
              })
            }
          }
        };

        vm.localizacionActual = function(){
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(objPosition)
            {
              var lon = objPosition.coords.longitude;
              var lat = objPosition.coords.latitude;
              vm.coords.lat = lat;
              vm.coords.long = lon;

              var geocoder = new google.maps.Geocoder();
              var latlng = new google.maps.LatLng(lat, lon);
              geocoder.geocode({'latLng': latlng}, function(results, status) {

              var calle = "";
              var num = "";
              var zip = "";
              var city = "";
              if (status == google.maps.GeocoderStatus.OK) {
                  //if (results[1]) {
                  if (results[0]) {
                    for (var i=0; i<results[0].address_components.length; i++) {
                      for (var b=0;b<results[0].address_components[i].types.length;b++) {
                        //console.log(results[0].address_components[i]);
                        if (results[0].address_components[i].types[b] == "route") {
                          calle += results[0].address_components[i].long_name;
                        }
                         if (results[0].address_components[i].types[b] == "street_number") {
                          num += " "+results[0].address_components[i].long_name;
                        }
                        if (results[0].address_components[i].types[b] == "locality") {
                          city += ", "+results[0].address_components[i].long_name;
                        }
                        else if (results[0].address_components[i].types[b] == "postal_code") {
                          zip += ", "+results[0].address_components[i].long_name;
                        }
                      }
                    }
                    vm.address = calle + num + city + zip;
                  }
                  vm.radio = 1.5;
                  vm.localizado = true;
                  vm.getCenterCentros(false);
                }
                
              });
            });
            
          }else{
            //console.log("No hay permiso");
          }
          
        }

        vm.windowParams = {
          test: function() {
          },
          window:vm.map.window
        }

        vm.mirarIai = function(centro,especialidad){
          //s1 Audiologia infantil
          /* delegs
            Infantil
            AcÃºfenos
            Implantes
            ReeducaciÃ³n
          */
          var res = false;
          if(
            centro.especialidad1==especialidad 
            || centro.especialidad2==especialidad 
            || centro.especialidad3==especialidad 
            || centro.especialidad4==especialidad)
            res = true;
          return res;
        } 

        vm.aplicarFiltros = function(){
          
          //digitalData.event.push({eventInfo:{ "eventName":"store_locator_filter", "attributes": "Click Filters button" },"category":{primaryCategory:"store_locator"}});
        }

        vm.getCenterCentros = function(init){
          return GoogleMapApi.then((function(_this) {
                return function(maps) {
                    vm.contadorVisibles = 0;
                    //console.log(vm.especialidad);

                    var centros = vm.centros;
                    $timeout(function(){
                        vm.markers = [];
                        var bounds = new google.maps.LatLngBounds();
                        var markersTotal = [];
                        var contadorVisible = false;
                        var actual = new google.maps.LatLng(Number(vm.coords.lat), Number(vm.coords.long));
                        var contador = 1;
                        console.log(actual);
                        for(var cont=0;cont<centros.length;cont++){

                              centros[cont].marker = true;
    
                            var myLatLng = new google.maps.LatLng(Number(centros[cont].lat), Number(centros[cont].long));
                            var distance = google.maps.geometry.spherical.computeDistanceBetween(actual, myLatLng);

                           
                            centros[cont].distance = google.maps.geometry.spherical.computeDistanceBetween(actual, myLatLng);
                            centros[cont].contador = contador;
                            var ret = {
                              latitude: Number(centros[cont].lat),
                              longitude: Number(centros[cont].long),
                              title: 'm' + cont,
                              distance : distance,
                              centro:centros[cont],
                              show: false,
                              icon: vm.icon//(centros[cont].esiai ? vm.iconiai : vm.icon)
                            };
                            ret["id"] = cont;

                            if(centros[cont].marker/* && centros[cont].country == vm.pais*/){
                              markersTotal.push(ret);
                              contador++;
                            }

                            
                            centros[cont].visible = false;

                            //1000metros
                            if(centros[cont].marker /*&& centros[cont].country == vm.pais*/ && parseInt(distance) <= (vm.radio*1000)){
                              centros[cont].visible = true;
                              vm.contadorVisibles++;

                              contadorVisible = true;
                              bounds.extend(myLatLng);
                              vm.bnds = {
                                  northeast: {
                                      latitude: bounds.getNorthEast().lat(),
                                      longitude: bounds.getNorthEast().lng()
                                  },
                                  southwest: {
                                      latitude: bounds.getSouthWest().lat(),
                                      longitude: bounds.getSouthWest().lng()
                                  }
                              }
                            }
                        }
                        if(!contadorVisible){
                          if(vm.radio<10000){
                            vm.radio+=2;
                            vm.getCenterCentros(false);
                          }else{
                            vm.busquedaError = true;
                          }
                        }else{
                          // PONEMOS LA DIRECCIÃ“N DE LA PERSONA en caso de que haya una direccion puesta
                          //COMENTADO POR PETICIÃ“N DE GAES.
                          if(vm.address){
                            var ret = {
                              latitude: Number(vm.coords.lat),
                              longitude: Number(vm.coords.long),
                              title: 'm' + cont,
                              centro:"",
                              show: false,
                              icon: '/img/i_acliente.png?v=1'
                            };
                            ret["id"] = cont;
                            markersTotal.push(ret);
                            bounds.extend(actual);
                            vm.bnds = {
                                northeast: {
                                    latitude: bounds.getNorthEast().lat(),
                                    longitude: bounds.getNorthEast().lng()
                                },
                                southwest: {
                                    latitude: bounds.getSouthWest().lat(),
                                    longitude: bounds.getSouthWest().lng()
                                }
                            }
                          }
                          vm.markers = markersTotal;
                          /*$timeout(function(){
                            digitalData.event = [{eventInfo:{ "eventName":"store_locator_search", "attributes": "Init locator" },"category":{primaryCategory:"store_locator"}}];
                            digitalData.component.storeLocator.name = "";
                            digitalData.component.storeLocator.geo = { latitude:"", longitude:"" };
                            digitalData.component.storeLocator.email = "";
                            digitalData.component.storeLocator.telephone = "";
                            digitalData.component.storeLocator.address = { addressCountry: "", addressLocality:"", addressRegion:'', postalCode:"", streetAddres:"" };
                            digitalData.component.filterTool.filterOptions =  "country,location,childrenAudiology,tinnitusTreatment,attentionImplanted,auditoryReeducation";
                            digitalData.component.filterTool.filterValues = vm.pais+","+(vm.address.replace(/,/g,"") ? vm.address.replace(/,/g,"") : 'Empty')+","+(vm.especialidad.s1 ? "Children Audiology" : "Not Applied")+","+(vm.especialidad.s2 ? "Tinnitus Treatment" : "Not Applied")+","+(vm.especialidad.s3 ? "Attention Implanted" : "Not Applied")+","+(vm.especialidad.s4 ? "Auditory Reeducation" : "Not Applied");
                            digitalData.component.filterTool.filterResults = vm.contadorVisibles;
                            digitalData.cfg = true;
                            if(init){
                              console.log("SEARCH");
                              window.wa_gc(digitalData, "view");
                            }else{
                              wa_gc("Filter Applied","link");
                            }
                          },1500);*/
                        }
                    })
                }
          })(this));
        }


        vm.setMarkersDireccion = function(callback){
            LocalizadorService.getTiendas(vm.pais,vm.provincia,vm.coords,vm.radio,vm.pais_id,function(data){
              console.log(vm.coords);
          console.log(vm.pais_id);

                if(data.centros.length>0){
                  vm.centros = data.centros;
                  console.log(vm.centros);
                  vm.getCenterCentros(true);
                  if (navigator.geolocation)
                    vm.localizacionActual();
                }else{
                    if(vm.radio<10000){
                        vm.radio+=10;
                        vm.setMarkersDireccion();
                    }else{
                      vm.centros = [];
                      vm.markers = [];
                    }
                }
                if(typeof(callback)!='undefined'){
                  callback();
                }
                
            });
        }



        vm.selectCentro = function(centroId) {
          vm.selectedCentroId = centroId;
        };

    }

})();