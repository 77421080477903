(function(){
     'use strict';

     angular
        .module('app')
        .factory('AnaliticasService', AnaliticasService);

    AnaliticasService.$inject = ['$http'];

    function AnaliticasService($http){

        var service= {
           getAnaliticas:getAnaliticas
        };

        return service;

        function getAnaliticas(callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
            $http.get('/uranovet/getanaliticas', { params : { lang_id:lang_id } }, config)
            .then(getContentSuccess,getContentFailed);
            function getContentSuccess(data){ 
                callback(data.data);
            }
            function getContentFailed(data){
                
                return false;
            }
        }
    }
})();