(function () {
    angular
        .module('app')
        .directive('swipervideo', setswipervideo);

    setswipervideo.$inject = ['$timeout', '$window'];
    function setswipervideo($timeout, $window) {
        return {
            link: function (scope, element, $attrs) {
                return $timeout(function () {
                    let videos = element[0].querySelectorAll('.vlite-video');
                    let swiper;
                    let autoSlideTimeout;

                    videos.forEach(video => {
                        let options = {
                            controls: false,
                            // autoplay: {
                            //     delay: 500,
                            //   },
                            volume: false,
                            fullscreen: true,
                            loop: true,
                            muted: true,
                            playPause: false,
                            progressBar: false,
                            time: false,
                            bigPlay: false,
                        };
                        let provider = null;

                        if (video.classList.contains('vimeo')) {
                            provider = "vimeo";
                        }

                        let vliteConfig = {
                            options: options,
                        };

                        if (provider) {
                            vliteConfig.provider = provider;
                        }
                        vliteConfig.onReady = function (player) {
                            player.on("ended", function () {
                                swiper.slideNext();
                            });
                        };

                        video.__vlitePlayer = new $window.Vlitejs(video, vliteConfig);
                    });

                    swiper = new Swiper(element[0], {
                        loop: true,
                        speed: 500,
                        effect: 'creative',
                        creativeEffect: {
                            prev: {
                                shadow: true,
                                translate: [0, 0, -1],
                            },
                            next: {
                                translate: ["100%", 0, 0],
                            },
                        },
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                        pagination: {
                            el: ".swiper-pagination",
                            type: "fraction",
                        },
                        on: {
                            init: function () {
                                handleAutoSlide(this.slides[this.activeIndex]);
                                // console.log(this.slides[this.activeIndex])
                                gsap.to(this.slides[this.activeIndex].querySelector('.img-absolute'), {
                                    opacity: 1,
                                    duration: 1,
                                    delay: 0.5,
                                    ease: "power2.out",
                                })
                                gsap.to(this.slides[this.activeIndex].querySelector('.card'), {
                                    height: "auto",
                                    overflow: "visible",
                                    duration: 1,
                                    delay: 1,
                                    ease: "power2.out",
                                })
                                gsap.to(this.slides[this.activeIndex].querySelector('.card .img'),  {
                                    duration: 0.5,
                                    delay: 0.9,
                                    opacity: 1,
                                    y: 0,
                                    delay: 1.5,
                                    ease: "power2.out",
                                });
                    
                                gsap.to(this.slides[this.activeIndex].querySelector('.card-body'), {
                                    duration: 0.5,
                                    delay: 0.9,
                                    opacity: 1,
                                    y: 0,
                                    delay: 1.5,
                                    ease: "power2.out",
                                });
                            },
                            beforeTransitionStart: function () {
                                animateTransition(this);
                            },
                            activeIndexChange: function () {
                                clearTimeout(autoSlideTimeout);
                                handleAutoSlide(this.slides[this.activeIndex]);
                            },
                        }
                    });

                    function handleAutoSlide(activeSlide) {
                        var activeVideo = activeSlide.querySelector('.vlite-video');
                        if (activeVideo) {
                            var player = activeVideo.__vlitePlayer.player;
                            if (player) {
                                player.play();
                            }
                        } else {
                            autoSlideNext();
                        }
                    }

                    function autoSlideNext() {
                        autoSlideTimeout = setTimeout(function () {
                            swiper.slideNext();
                        }, 8000);
                    }
                });
            }
        };

        function animateTransition(swiper) {
            const currentSlide = swiper.slides[swiper.previousIndex];
            const nextSlide = swiper.slides[swiper.activeIndex];

            gsap.to(currentSlide.querySelector('.card .img'), {
                duration: 0.1,
                opacity: 0,
                y: 40
            });
            gsap.to(currentSlide.querySelector('.card-body'), {
                duration: 0.3,
                opacity: 0,
                y: 40
            });
            gsap.to(currentSlide.querySelector('.card '), {
                duration: 0.5,
                delay: 0.1,
                height: 0,
                overflow: "hidden"
            });

            gsap.fromTo(nextSlide.querySelector('.card .img'), {
                opacity: 0,
                y: 40,
            }, {
                duration: 0.5,
                delay: 0.9,
                opacity: 1,
                y: 0,
                ease: "power2.out",
            });

            gsap.fromTo(nextSlide.querySelector('.card-body'), {
                opacity: 0,
                y: 40,
            }, {
                duration: 0.5,
                delay: 0.9,
                opacity: 1,
                y: 0,
                ease: "power2.out",
            });
            gsap.fromTo(nextSlide.querySelector('.card'), {
                height: 0,
                overflow: "hidden"
            }, {
                duration: 0.5,
                delay: 0.5,
                height: 'auto',
                overflow: "visible",
                ease: "power2.out",
            });
        }
    }
})();
