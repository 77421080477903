(function(){
     'use strict';

     angular
        .module('app')
        .factory('LocalizadorService', LocalizadorService);

    LocalizadorService.$inject = ['$http'];

    function LocalizadorService($http){


        var service= {
           getTiendas:getTiendas
        };

        return service;
 		function getTiendas(pais,provincia,coords,radio,pais_id,callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
            /*var data = $.param({
               id:vm.content_id
            });*/
            $http.get('/ajax/getcentersjson?lat='+coords.lat+"&long="+coords.long+"&ratio="+radio+"&pais="+pais+"&provincia="+provincia+"&pais_id="+pais_id, [], config)
            .then(getContentSuccess)
            .catch(getContentFailed);
            function getContentSuccess(data){ 
                callback(data.data);
            }
            function getContentFailed(data){
                
                return false;
            }
        }
    }
})();