(function(){
     'use strict';

     angular
        .module('app')
        .factory('ProductService', ProductService);

    ProductService.$inject = ['$http'];

    function ProductService($http){

        var service= {
           getProductByTipologia:getProductByTipologia
        };

        return service;
 		function getProductByTipologia(tipologia,callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }

            $http.get('/uranovet/getproductbytipologia', { params : { tipologia : tipologia,lang_id:lang_id } }, config)
            .then(getContentSuccess,getContentFailed);
            function getContentSuccess(data){ 
                callback(data.data);
            }
            function getContentFailed(data){
                
                return false;
            }
        }

      
    }
})();