(function () {
    angular
        .module('app')
        .directive('swiper', setswiper);

    setswiper.$inject = ['$timeout', '$window'];
    function setswiper($timeout, $window) {
        return {
            link: function (scope, element, $attrs) {
                return $timeout(function () {
                    let swiperConfig = {};
                    let swiper;

                    function initializeSwiper() {
                        if ($attrs.swiper.includes("pagination")) {
                            swiperConfig.pagination = {
                                el: ".swiper-pagination",
                                type: "fraction",
                            };
                        }

                        if ($attrs.swiper.includes("navigation")) {
                            swiperConfig.navigation = {
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            };
                        }

                        if ($attrs.swiper.includes("grabCursor")) {
                            swiperConfig.grabCursor = true;
                        }

                        if ($attrs.swiper.includes("slidesPerView")) {
                            swiperConfig.slidesPerView = "auto";
                        }

                        if ($attrs.swiper.includes("spaceBetween")) {
                            swiperConfig.spaceBetween = 24;
                        }
                        if ($attrs.swiper.includes("movile")) {
                            if ($window.matchMedia("(max-width: 979.98px)").matches) {
                                swiper = new Swiper(element[0], swiperConfig);
                                initIntersectionObserver(swiper, element);
                            } else {
                                initIntersectionObserver(null, element);
                            }
                        } else {
                            swiper = new Swiper(element[0], swiperConfig);
                        }
                    }

                    function destroySwiper() {
                        if (swiper) {
                            swiper.destroy(true, true);
                            swiper = null;
                        }
                    }

                    function checkAndInitializeSwiper() {
                        destroySwiper();
                        initializeSwiper();
                    }

                    angular.element($window).on('resize', checkAndInitializeSwiper);

                    $timeout(checkAndInitializeSwiper);

                    scope.$on('$destroy', function () {
                        destroySwiper();
                        angular.element($window).off('resize', checkAndInitializeSwiper);
                    });
                });
            }
        }

        function initIntersectionObserver(swiper, element) {
            const sections = document.querySelectorAll('.js-link-slide');
            const links = element[0].querySelectorAll('.swiper-slide a');

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        links.forEach(link => link.parentElement.classList.remove('active'));

                        const activeLink = element[0].querySelector(`a[href="#${entry.target.id}"]`);
                        if (activeLink) {
                            activeLink.parentElement.classList.add('active');

                            const index = Array.from(links).indexOf(activeLink);
                            if (index !== -1) {
                                if (swiper !== null) {
                                    swiper.slideTo(index);
                                }
                            }
                        }
                    }
                });
            }, {
                threshold: 0.5
            });

            sections.forEach(section => observer.observe(section));
        }
    }
})();
