(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name InclinicController
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('InclinicController', InclinicController);

    InclinicController.$inject = ["$timeout","InclinicService","$uibModal","$location",'$anchorScroll'];

    function InclinicController($timeout,InclinicService,$uibModal,$location,$anchorScroll)
    {
    	var vm = this;
    	vm.minipcs = [];

    	 vm.socket = io.connect("https://maquinas.uranovet.com:4000",{ transports : ['websocket'], 'flash policy port':4000, /*secure: true*/}); 
      	 vm.socket.emit("minipc:getmaquinas");
      	 vm.socket.on("minipc:getmaquinas",function(data){
      	 	$timeout(function(){
	      	 	vm.minipcs = data.minipcs;
      	 	})
      	 	console.log(vm.minipcs);
      	 })

        vm.editMinipcMod = "";
        vm.editMinipc = function(minipc){
             vm.editMinipcMod = $uibModal.open({
                  animation: true,
                  ariaLabelledBy: 'modal-title-top',
                  ariaDescribedBy: 'modal-body-top',
                  templateUrl: 'editminipc.html',
                  size: 'right',
                  //backdrop:'static',
                  controller: function($scope) {
                    $scope.minipc = minipc;
                    InclinicService.getWifiRasp(minipc.rasp_id,function(data){
                        $scope.wifiinfo = data.info;
                    })

                    $scope.okForm = function(){
                        InclinicService.setWifiRasp(minipc.rasp_id,$scope.wifiinfo,function(data){
                           $scope.wifiinfo = data.info;
                           vm.socket.emit("minipc:changewifi",{ minipc:minipc });

                        })
                        vm.editMinipcMod.close();
                    }
                    $scope.koForm = function(){
                        vm.editMinipcMod.close();
                    }
                   
                  }
             });
        }

        vm.init = function(){
          InclinicService.getAllInclinic(function(data){

          })
        }
               
    }


})();