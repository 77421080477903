(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name ProductController
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('ProductController', ProductController);

    ProductController.$inject = ["ProductService","$timeout","$location",'$anchorScroll'];

    function ProductController(ProductService,$timeout,$location,$anchorScroll)
    {

    	var vm = this;
        vm.tipologia = "";
    	vm.tipologiaAc = "";
    	vm.productos = [];
    	vm.loading = false;

        vm.swiperHome = "";
    
        vm.swiperTip = {
            speed: 400,
            loop:true,
            breakpoints: { '480': { 'slidesPerView': '5' },'640': { 'slidesPerView': '7' },'960': { 'slidesPerView': '9' }},
            spaceBetween: 0,
            roundLengths: true,
            slidesPerView:3,
            roundLengths:true,
            observer: true,
            centeredSlides:true,
            on: {
                click: function(e){
                    //console.log("click");
                    $timeout(function(){
                        vm.loading = true;
                    });
                    //vm.init(this.clickedSlide.dataset.id);
                    //console.log(this);
                    this.slideTo(this.clickedIndex)
                    //console.log();
                    //console.log(e);
                },
                slideChangeTransitionEnd:function(){
                    $timeout(function(){
                        vm.loading = true;
                    });
                    var iddata= $('.tabs-products .swiper-slide-active').attr("data-id");
                    vm.init(iddata);
                }/*,
                touchEnd: function(e){
                   // console.log(this);
                    //console.log(e);
                    var iddata= $('.tabs-products .swiper-slide-active').attr("data-id");
                    vm.init(iddata);
                    //vm.init(this.clickedSlide.dataset.id);

                }*/
            }
        };

    	vm.init = function(tipologia){
    		vm.loading = true;
            vm.tipologia = tipologia;

            $timeout(function(){
        		ProductService.getProductByTipologia(vm.tipologia,function(data){
                    vm.productos = data.productos;
        			vm.tipologiaAc = data.tipologia;
                    $timeout(function(){
                        vm.swiperHome = new Swiper('.productshome', {
                            speed: 400,
                            loop:false,
                            slidesPerView: 'auto',
                            spaceBetween: 0,
                            roundLengths: true,
                            observer: true
                        });
                        $timeout(function(){
                            vm.loading = false;
                        },200)

                    })
                });
            },200);
    	}
               
    }


})();